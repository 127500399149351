import React, { useEffect, lazy, Suspense } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import {
    OrderPayload,
    isProjectIdValid,
    isBusinessNameValid,
    isBudgetCodeValid,
    isBusinessAppValid,
    isAppNameValid,
    isAppShortNameValid,
    isAppShortNameExist,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
    costEstimateState,
    uploadedFilesState,
} from 'pages/reference-implementation/provisioning/RI001/store';
import { orderSuccessPopup, orderId } from 'pages/reference-implementation/store';
import { commonStyles as style } from 'assets/style';
import { selectOrder } from 'pages/orders/store';
// import { RIStyles } from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import { validateObject } from 'utils/validateObject';
import { getOrderPayload } from 'pages/reference-implementation/provisioning/RI001/utils/getPayload';
import { isReadyToSubmit } from 'pages/reference-implementation/provisioning/RI001/utils/validation';
import PropTypes from 'prop-types';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';

const ErrorBanner = lazy(() => import('pages/reference-implementation/components/error-banner'));

export const SubmitForValidation = (props) => {
    const { isBgApiLoading } = props;
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const history = useHistory();
    const [order, setOrder] = useRecoilState(OrderPayload);
    const projectIdValid = useRecoilValue(isProjectIdValid);
    // const adGroupError = useRecoilValue(adGroupErrorMsg);
    const businessUnitValid = useRecoilValue(isBusinessNameValid);
    const budgetCodeValid = useRecoilValue(isBudgetCodeValid);
    const businessAppValid = useRecoilState(isBusinessAppValid);
    const [costEstimate] = useRecoilState(costEstimateState);
    const appNameValid = useRecoilValue(isAppNameValid);
    const appShortNameVaild = useRecoilValue(isAppShortNameValid);
    const appShortNameExist = useRecoilValue(isAppShortNameExist);
    const nonPrivilegedADGroupValid = useRecoilValue(isNonPrivilegedADGroupValid);
    const privilegedADGroupValid = useRecoilValue(isPrivilegedADGroupValid);
    const uploadedFiles = useRecoilValue(uploadedFilesState);
    const [, setSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [, setOrderId] = useRecoilState(orderId);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const [, setSelectedOrderId] = useRecoilState(selectOrder);
    const riEndpoints = getEndpointForCatalogCode(catalogCode);

    const submitOrderApi = useMutation((payload) => axios.post(riEndpoints.Jwt.submitOrder, payload));
    const saveChangesApi = useMutation((payload) => axios.put(`${riEndpoints.Jwt.reSubmit}`, payload));

    const handleRedirect = async () => {
        await setSelectedOrderId(cartItemId);
        history.push('/orders');
    };

    const handleSubmit = async () => {
        const payload = await getOrderPayload({ order, uploadedFiles });
        // eslint-disable-next-line
        try {
            const validate = await validateObject({ data: payload, catalogCode });
            if (validate?.isValid) {
                if (cartItemId && operation && catalogServiceId) {
                    // const reSubmitPayload = { ...payload, 'order-id': cartItemId };
                    saveChangesApi.mutate(payload);
                }
                if (!cartItemId && !operation && !catalogServiceId) {
                    submitOrderApi?.mutate(payload);
                }
            }
        } catch (e) {
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const handleValidation = () => {
        if (
            !isReadyToSubmit({
                order,
                businessAppValid,
                projectIdValid,
                businessUnitValid,
                appNameValid,
                appShortNameVaild,
                appShortNameExist,
                budgetCodeValid,
                nonPrivilegedADGroupValid,
                privilegedADGroupValid,
                uploadedFiles,
            }) &&
            (costEstimate?.totalOneTimeCost?.estCost || costEstimate?.totalRecurringCost?.estCost)
        ) {
            handleSubmit();
        } else {
            setOrder({ ...order, isTouched: true });
            handleClickErrorBanner();
        }
    };

    useEffect(() => {
        if (saveChangesApi?.data?.data) {
            handleRedirect();
        }
    }, [saveChangesApi?.isSuccess]);

    useEffect(() => {
        if (submitOrderApi?.isSuccess) {
            setOrderId(submitOrderApi?.data?.data?.orderId);
            setSuccessPopup(true);
        }
    }, [submitOrderApi?.isSuccess]);

    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={submitOrderApi?.isLoading || isBgApiLoading}
                        sx={style.primaryButton}
                        data-testid="add-to-cart-btn"
                    >
                        {submitOrderApi?.isLoading && !isBgApiLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit for Validation'}
                    </Btn>
                )}
                {cartItemId && operation && catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={saveChangesApi?.isLoading || isBgApiLoading}
                        // sx={RIStyles?.CostSummary?.button}
                        sx={style.primaryButton}
                        // color="cmpPrimary2"
                        data-testid="save-changes-btn"
                    >
                        {saveChangesApi?.isLoading && !isBgApiLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit for Re-validation'}
                    </Btn>
                )}
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

SubmitForValidation.propTypes = {
    isBgApiLoading: PropTypes.bool,
};

SubmitForValidation.defaultProps = {
    isBgApiLoading: false,
};

export default React.memo(SubmitForValidation);
