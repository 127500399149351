import React, { lazy, Suspense } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    OrderPayload,
    isProjectIdValid,
    // adGroupErrorMsg,
    showPreviewOrder,
    previewDetails,
    isBusinessNameValid,
    isBudgetCodeValid,
    isAppShortNameValid,
    isAppShortNameExist,
    previewOrderError,
    // contributorErrorMsg,
    isBusinessAppValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
    isAppUrlValid,
    isValidOwners,
    // isAppContextPathValid,
    isAppNameValid,
    isValidappSupportMailId,
} from 'pages/reference-implementation/provisioning/RI002O/store';
import { commonStyles as style } from 'assets/style';
import Btn from 'components/button';
import { getpreviewDetails } from 'pages/reference-implementation/provisioning/RI002O/utils/preview';
import { isReadyToSubmitNew } from 'pages/reference-implementation/provisioning/RI002O/utils/validation';
import PropTypes from 'prop-types';
import { customToast } from 'api/utils';

const ErrorBanner = lazy(() => import('pages/reference-implementation/components/error-banner'));

export const PreviewOrder = (props) => {
    const { isBgApiLoading } = props;
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const projectIdValid = useRecoilValue(isProjectIdValid);
    // const adGroupError = useRecoilValue(adGroupErrorMsg);
    // const contributorError = useRecoilValue(contributorErrorMsg);
    const businessUnitValid = useRecoilValue(isBusinessNameValid);
    const budgetCodeValid = useRecoilValue(isBudgetCodeValid);
    const appShortNameValid = useRecoilValue(isAppShortNameValid);
    const appShortNameExist = useRecoilValue(isAppShortNameExist);
    const businessAppValid = useRecoilValue(isBusinessAppValid);
    const nonPrivilegedADGroupValid = useRecoilValue(isNonPrivilegedADGroupValid);
    const privilegedADGroupValid = useRecoilValue(isPrivilegedADGroupValid);
    const appUrlValid = useRecoilValue(isAppUrlValid);
    const validOwners = useRecoilValue(isValidOwners);
    const appNameValid = useRecoilValue(isAppNameValid);
    // const appContextPathValid = useRecoilValue(isAppContextPathValid);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const [, setShowPreview] = useRecoilState(showPreviewOrder);
    const [, setPreviewDetails] = useRecoilState(previewDetails);
    const [previewError, setPreviewError] = useRecoilState(previewOrderError);
    const validApplicationSupportMail = useRecoilValue(isValidappSupportMailId);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const handleValidation = () => {
        document.getElementById('applayoutbox').scrollTop = 0;
        if (previewError?.isPreviewError) {
            setOrder({ ...order, isTouched: false });
            customToast(previewError?.message);
            setLoading(false);
        } else {
            setLoading(true);
            const formValidation = isReadyToSubmitNew({
                order,
                businessAppValid,
                projectIdValid,
                businessUnitValid,
                budgetCodeValid,
                appShortNameValid,
                appShortNameExist,
                nonPrivilegedADGroupValid,
                privilegedADGroupValid,
                appNameValid,
                appUrlValid,
                validApplicationSupportMail,
                validOwners,
            });
            if (formValidation?.isValid && order?.environmentDetails) {
                setPreviewDetails(getpreviewDetails({ order }));
                setShowPreview('pre-preview-order');
                setLoading(false);
            } else {
                setOrder({ ...order, isTouched: true });
                setPreviewError({ ...previewError, isPreviewError: false, message: '' });
                handleClickErrorBanner();
                setLoading(false);
            }
        }
    };
    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            {' '}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Btn
                    size="medium"
                    onClick={() => handleValidation()}
                    variant="outlined"
                    disabled={isLoading || isBgApiLoading}
                    sx={style.secondaryButton}
                    data-testid="add-to-cart-btn"
                >
                    {isLoading && !isBgApiLoading ? <CircularProgress color="inherit" size={16} /> : 'Preview Order'}
                </Btn>
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click preview."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

PreviewOrder.propTypes = {
    isBgApiLoading: PropTypes.bool,
};

PreviewOrder.defaultProps = {
    isBgApiLoading: false,
};

export default React.memo(PreviewOrder);
