import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import FormCard from 'pages/reference-implementation/components/form-card';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI001/store';
import Form from 'pages/reference-implementation/provisioning/RI001/components';
import UploadScan from 'pages/reference-implementation/provisioning/RI001/components/upload-scan';
import Heading from 'pages/reference-implementation/components/Heading';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { RIStyles } from 'pages/reference-implementation/Style';
import { RICatalogCode001, RICatalogCode001A } from 'pages/reference-implementation/utils/RICatalogCode';

const IndexForm = () => {
    const order = useRecoilValue(OrderPayload);

    return (
        <Box flex={3}>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Heading title="Project" />
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Form.PreviousOrderID />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ProjectId />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessUnit />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.Sector />
                    </Grid>
                    {order?.sector && ['cgf']?.includes(order?.sector?.toLowerCase()) && (
                        <Grid item xs={12} sm={6}>
                            <Form.Division />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Form.BudgetCode />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BillingInfo />
                    </Grid>
                </Grid>
            </FormCard>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Heading title="Application" />
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        {' '}
                        <Form.Environments />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.DataClassification />
                    </Grid>
                    {order?.environment && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Form.ApplicationService />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.AppOwnerSearch />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.ApplicationName />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.ApplicationShortName />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.BusinessContact />
                            </Grid>
                            {[RICatalogCode001, RICatalogCode001A]?.includes(order?.catalogCode) && (
                                <Grid item xs={12} sm={12}>
                                    <UploadScan />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </FormCard>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Heading title="Access Management" />
                        <Box sx={RIStyles?.form?.accessMgmt?.infoIconWrapper}>
                            <InputInfo
                                // iconFontSize="16px"
                                maxWidth="400px"
                                title={
                                    <>
                                        <Box sx={RIStyles.adinfoStyle.wrapper}>
                                            <Box sx={RIStyles.adinfoStyle.namingRuleWrapper}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sx={RIStyles.adinfoStyle.topLeftNamingRule}>
                                                        <Typography sx={RIStyles.infoStyle.typographyStyle}>
                                                            If the AD Groups are not existing, new groups will be created in the below format.
                                                        </Typography>
                                                        <ul>
                                                            <li>
                                                                <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                    Reader: Team.App.&lt;Sector&gt;.&lt;Application Name&gt;
                                                                </Typography>
                                                            </li>
                                                            <li>
                                                                <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                    Contributor: Team.Support.&lt;Sector&gt;.&lt;Application Name&gt;
                                                                </Typography>
                                                            </li>
                                                        </ul>

                                                        <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                            if the AD Groups are existing, Please Choose AD Groups
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </>
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={2}>
                    {!['production']?.includes(order?.environment?.toLowerCase()) && (
                        <Grid item xs={12} sm={12}>
                            <Form.NonPrivilegedADGroup />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                        <Form.PrivilegedADGroup />
                    </Grid>
                </Grid>
            </FormCard>
        </Box>
    );
};

export default IndexForm;
