import React from 'react';
import { Box, Card, Divider, Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import Btn from 'components/button';
import { showPreviewOrder } from 'pages/reference-implementation/provisioning/RI001/store';
import { useRecoilState } from 'recoil';
import LabelValue from 'pages/reference-implementation/components/label-value';
import Chip from 'components/Chip';
import Alert from 'components/alert';
import PreviewStyles from 'pages/reference-implementation/components/preview-details/style';
import { FAILED, SUCCESS } from 'utils/ValidationStatus';
import { exportToJson, getAccessToken, getValueFromKey } from 'utils';
import { riCatalogs } from 'store';
import DestroyAlert from 'components/order-summary-details/destroy-alert';
import mappingLabel from 'utils/mappingLabel';
import html2canvas from 'html2canvas';
import { addLabelClass, addValueClass, generatePDF, removeLabelClass, removeValueClass } from 'pages/reference-implementation/utils';
// import autoTable from 'jspdf-autotable';
import { commonStyles as style } from 'assets/style';

const PreviewOrderDetails = (props) => {
    const { order, details, onDownloadConfig, isDownloading } = props;
    const gpid = getAccessToken('claims')?.gpid;
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    const [additionalInfo, setAdditionalInfo] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const container = React.useRef(null);

    React.useEffect(() => {
        const getADGroupOwner = () => {
            let owner = '';
            if (order?.nonPrivilegedADGroupOwnerDetails) {
                owner = order?.nonPrivilegedADGroupOwnerDetails?.name;
            } else if (order?.privilegedADGroupOwnerDetails) {
                owner = order?.privilegedADGroupOwnerDetails?.name;
            }
            return owner;
        };
        setAdditionalInfo({
            'Application Service': order?.appService,
            'App Owner': order?.appOwnerDetails.name,
            ...(order?.environment?.toLowerCase() !== 'production' &&
                (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                    'AD Group Owner': getADGroupOwner(),
                }),
            ...(order?.environment?.toLowerCase() === 'production' &&
                order?.privilegedADGroup === 'No' && {
                    'AD Group Owner': order?.privilegedADGroupOwnerDetails?.name,
                }),
        });
    }, []);

    const getCatalogType = () => {
        const data = cmpRiCatalogs?.find((item) => item?.['reference-implementation-id'] === order?.catalogCode);
        return data?.name;
    };
    const getKey = (resourceName) => {
        return resourceName !== 'Key Vault' ? 'Role Assignments' : 'Access Policy';
    };

    const handleGeneratePdf = () => {
        setLoading(true);
        const label = document.getElementsByClassName('label');
        const value = document.getElementsByClassName('value');
        try {
            addLabelClass(label);
            addValueClass(value);

            const divToPrint = document.getElementById('divToPrint');
            html2canvas(divToPrint, {
                useCORS: true,
                allowTaint: true,
                scrollY: -window.scrollY,
            }).then((canvas) => {
                generatePDF(canvas, order);
                setLoading(false);
                removeLabelClass(label);
                removeValueClass(value);
            });
        } catch (err) {
            setLoading(false);
            removeLabelClass(label);
            removeValueClass(value);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} px={7} py={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={PreviewStyles?.previewDetailsTextWrap}>
                    <Typography component="span" sx={PreviewStyles?.previewDetailsText}>
                        Provision Details (Preview)
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    {(showPreview === 'pre-preview-order' ||
                        (gpid === order?.createdBy &&
                            order?.currentOrderStatus === 'Validation' &&
                            [SUCCESS, FAILED]?.includes(order?.validationDetails?.status))) && (
                        <Btn
                            size="medium"
                            onClick={() => setShowPreview(null)}
                            variant="outlined"
                            // color="cmpPrimary2"
                            disabled={loading}
                            data-testid="edit-Btn"
                            // sx={PreviewStyles?.editBtn}
                            sx={{ ...style.secondaryMediumButton, minWidth: '64px', width: 'auto' }}
                        >
                            Edit Order
                        </Btn>
                    )}
                    {showPreview === 'pre-preview-order' && (
                        <Btn
                            size="medium"
                            onClick={() => {
                                exportToJson(
                                    order?.environmentDetails?.['tech-input-json'] || {},
                                    `${order?.applicationName?.replaceAll(' ', '')}_${order?.environment?.replaceAll(' ', '')}_tech_input_json`
                                );
                            }}
                            variant="outlined"
                            // color="cmpPrimary2"
                            data-testid="edit-Btn"
                            // sx={PreviewStyles?.downloadBtn}
                            sx={{ ...style.secondaryMediumButton, minWidth: '64px', width: '252px' }}
                        >
                            Download Technical Input
                        </Btn>
                    )}
                    {showPreview === 'pre-preview-order' && (
                        <Btn
                            size="medium"
                            onClick={() => {
                                // printDocument();
                                handleGeneratePdf();
                            }}
                            variant="outlined"
                            disabled={loading}
                            // color="cmpPrimary2"
                            data-testid="edit-Btn"
                            // sx={PreviewStyles?.downloadPdfBtn}
                            sx={{ ...style.secondaryMediumButton, width: 'auto', minWidth: '168px' }}
                            startIcon={<img src="/static/images/pdf.png" alt="download" width={30} height={30} alignItem="left" />}
                        >
                            {loading ? (
                                <>
                                    Exporting&nbsp;&nbsp;&nbsp;
                                    <CircularProgress color="inherit" size={16} />
                                </>
                            ) : (
                                'Export to PDF'
                            )}
                        </Btn>
                    )}{' '}
                    {showPreview !== 'pre-preview-order' && [SUCCESS]?.includes(order?.validationDetails?.status) && (
                        <Btn
                            size="medium"
                            disabled={isDownloading}
                            onClick={() => onDownloadConfig()}
                            variant="contained"
                            // color="cmpPrimary2"
                            data-testid="edit-Btn"
                            className={isDownloading && 'btn-disabled'}
                            // sx={PreviewStyles?.downloadBtn}
                            sx={{ ...style.primaryMediumButton, minWidth: '64px', width: '252px' }}
                        >
                            {isDownloading ? <CircularProgress color="inherit" size={20} /> : 'View Terraform Plan Output'}
                        </Btn>
                    )}
                </Box>
            </Box>
            {showPreview !== 'pre-preview-order' && [FAILED]?.includes(order?.validationDetails?.status) && (
                <Box sx={{ width: '100%', pt: '10px' }}>
                    <Alert
                        message={
                            <Box>
                                <Box sx={{ color: '#1E1E1E', size: '16px', fontWeight: 'medium' }}>Error Report</Box>
                                <Box sx={{ maxHeight: '200px', overflowY: 'scroll', width: '100%' }} className="customized-scrollbar-error">
                                    <Box>{order?.validationDetails?.statusDetail}</Box>
                                </Box>
                            </Box>
                        }
                        borderLeftColor="#DB1E36"
                        backgroundColor="#FCE8EA"
                        color="#000000"
                        boxShadow="none"
                    />
                </Box>
            )}
            {order?.validationDetails?.destroyingResources && (
                <Box sx={{ width: '100%', pt: '10px' }}>
                    <DestroyAlert />
                </Box>
            )}
            <div style={{ display: 'none' }}>
                <div id="tableToPrint" className="tableToPrint">
                    <div style={{ display: 'flex', color: '#ffffff', backgroundColor: '#1478D4', justifyContent: 'center', alignItems: 'center' }}>
                        <img src="/static/images/logo.png" alt="" width={90} style={{ marginRight: '16px' }} />
                        <h3>{`${order?.applicationName?.replaceAll(' ', '')}_${order?.environment?.replaceAll(' ', '')} - Provision Details (Preview)`}</h3>
                    </div>
                    <table
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                        }}
                    >
                        {Object.keys(details).map(
                            (key) =>
                                key !== 'resources' && (
                                    <tr>
                                        <td>{key !== 'Location' ? key : 'Azure Region'}</td>
                                        <td>{details[key]}</td>
                                    </tr>
                                )
                        )}
                        {Object.keys(additionalInfo).map((key) => (
                            <tr>
                                <td>{key}</td>
                                <td>{additionalInfo[key]}</td>
                            </tr>
                        ))}
                        {details?.resources?.map((resource) => (
                            <>
                                <tr style={{ backgroundColor: '#64C6EA', color: '#FFFFFF' }}>
                                    <td>{resource['Resource Type']}</td>
                                </tr>
                                {Object.keys(resource['Resource Properties'])?.map((resourceProperty) => (
                                    <tr>
                                        <td>{resourceProperty}</td>
                                        <td>{resource['Resource Properties'][resourceProperty]}</td>
                                    </tr>
                                ))}
                                {Object.keys(resource['Role Assignments'] || {})?.map((roles) => (
                                    <tr>
                                        <td>{roles}</td>
                                        <td>{resource['Role Assignments'][roles]?.join(', ')}</td>
                                    </tr>
                                ))}
                                {resource['Resource Type'] === 'Key Vault' &&
                                    Object.keys(resource['Access Policy'] || {})?.map((roles) => (
                                        <tr>
                                            <td>{roles}</td>
                                            <td>{resource['Access Policy'][roles]?.join(', ')}</td>
                                        </tr>
                                    ))}
                            </>
                        ))}
                    </table>
                </div>
            </div>
            <Box id="divToPrint" className="divToPrint" ref={container} height="100%" width="100%" sx={PreviewStyles?.wrapper} gap={2}>
                <Box flex={1}>
                    <Box>
                        <Card sx={PreviewStyles?.card}>
                            <Typography component="span" sx={PreviewStyles?.generalInfo}>
                                {showPreview !== 'pre-preview-order' ? 'Validation Summary' : 'General Info'}
                            </Typography>
                            <Box sx={PreviewStyles?.generalWrapper}>
                                {showPreview !== 'pre-preview-order' && (
                                    <>
                                        <>
                                            <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                            <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                Status:
                                            </Typography>
                                            <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                {![FAILED, SUCCESS]?.includes(order?.validationDetails?.status) ? (
                                                    <Box
                                                        className="blink-bg"
                                                        component="div"
                                                        sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', height: '30px', width: '70px' }}
                                                    >
                                                        <Box sx={{ textTransform: 'capitalize' }}>{order?.validationDetails?.status}</Box>
                                                    </Box>
                                                ) : (
                                                    <Chip status={order?.validationDetails?.status || null}>
                                                        <Box sx={{ textTransform: 'capitalize' }}>{order?.validationDetails?.status}</Box>
                                                    </Chip>
                                                )}
                                            </Typography>
                                        </>

                                        {![FAILED]?.includes(order?.validationDetails?.status) && (
                                            <>
                                                <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                                <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                    Estimated Time:
                                                </Typography>
                                                <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                    5 Minutes
                                                </Typography>
                                            </>
                                        )}
                                        <>
                                            <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                            <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                Order ID:
                                            </Typography>
                                            <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                {order?.orderId}
                                            </Typography>
                                        </>
                                        <>
                                            <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                            <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                Catalog Type:
                                            </Typography>
                                            <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                {getCatalogType()}
                                            </Typography>
                                        </>
                                    </>
                                )}
                                {details &&
                                    Object.keys(details)?.length &&
                                    Object.keys(details).map((key, idx) => (
                                        <React.Fragment key={idx}>
                                            {key !== 'resources' && (
                                                <>
                                                    <Divider variant="horizontal" sx={PreviewStyles?.devider} />

                                                    <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                        {key !== 'Location' ? key : 'Azure Region'}:
                                                    </Typography>
                                                    <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                        {typeof details?.[key] === 'string' ? details?.[key] : JSON.stringify(details?.[key])}
                                                    </Typography>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                {additionalInfo &&
                                    Object.keys(additionalInfo)?.length &&
                                    Object.keys(additionalInfo).map((key, idx) => (
                                        <React.Fragment key={idx}>
                                            <Divider variant="horizontal" sx={PreviewStyles?.devider} />
                                            <Typography component="span" sx={PreviewStyles?.generalKey}>
                                                {key}
                                            </Typography>
                                            <Typography component="span" sx={PreviewStyles?.generalValue}>
                                                {additionalInfo?.[key]}
                                            </Typography>
                                        </React.Fragment>
                                    ))}
                            </Box>
                        </Card>
                    </Box>
                </Box>
                <Box flex={3} sx={PreviewStyles?.resourceWrapper}>
                    <Box>
                        {details?.resources?.map((resource, resIdx) => (
                            <Card sx={PreviewStyles?.reourceCard} key={`card-${resIdx}`}>
                                <Typography component="span" sx={PreviewStyles?.resourceType}>
                                    {resource['Resource Type']}
                                </Typography>
                                {Object.keys(resource['Resource Properties'])?.map((resourceProperty, resourcePropertyIndex) => (
                                    <Box className="ripreview-details" sx={PreviewStyles?.resourceProperty} key={`resProp-${resourcePropertyIndex}`}>
                                        <LabelValue
                                            istextTransform={false}
                                            width={220}
                                            label={getValueFromKey(resourceProperty, mappingLabel)}
                                            value={
                                                Array.isArray(resource['Resource Properties'][resourceProperty])
                                                    ? resource['Resource Properties'][resourceProperty]?.map((storagename, storageIdx) => (
                                                          <Chip status="storage" key={`storageName-${storageIdx}`}>
                                                              <Box sx={{ textTransform: 'capitalize' }}>{storagename}</Box>
                                                          </Chip>
                                                      ))
                                                    : resource['Resource Properties'][resourceProperty]
                                            }
                                        />
                                    </Box>
                                ))}
                                {resource[getKey(resource['Resource Type'])] !== null && (
                                    <Box sx={PreviewStyles?.roleAssgnWrapper} gap={1}>
                                        <Box flex={1} sx={PreviewStyles?.roleAssignMent}>
                                            {getKey(resource['Resource Type'])}:
                                        </Box>
                                        <Box flex={3}>
                                            <Box sx={PreviewStyles?.roleAssignMentVal}>
                                                {Object?.keys(resource[getKey(resource['Resource Type'])])?.map((role, roleIndex) => (
                                                    <Box flex={1.5} key={`role-${roleIndex}`} sx={PreviewStyles?.roleAssignMentWrapper}>
                                                        <Typography component="span" sx={PreviewStyles?.role}>
                                                            {role}&#40;s&#41;
                                                        </Typography>
                                                        {resource[getKey(resource['Resource Type'])][role]?.map((item, index) => (
                                                            <Box key={`assign-${index}`} sx={PreviewStyles?.roleVal}>
                                                                {item}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Card>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

PreviewOrderDetails.propTypes = {
    order: PropTypes.any,
    details: PropTypes.any,
    isDownloading: PropTypes.bool,
    onDownloadConfig: PropTypes.func,
};
PreviewOrderDetails.defaultProps = {
    order: {},
    details: {},
    isDownloading: false,
    onDownloadConfig: () => null,
};
export default PreviewOrderDetails;
