export const RiCatalogsMapping = [
    {
        'reference-implementation-id': 'RI001',
        iconPath: '/static/images/RI/RI001.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA001.JPG',
    },
    {
        'reference-implementation-id': 'RI001A',
        iconPath: '/static/images/RI/RI001.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA001.JPG',
    },
    {
        'reference-implementation-id': 'RI002',
        iconPath: '/static/images/RI/RI002.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA002.JPG',
    },
    {
        'reference-implementation-id': 'RI003',
        iconPath: '/static/images/RI/RI003.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA003.JPG',
    },
    {
        'reference-implementation-id': 'RI003O',
        iconPath: '/static/images/RI/RI003O.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA003O.JPG',
    },
    {
        'reference-implementation-id': 'RI004',
        iconPath: '/static/images/RI/RI004.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA004.JPG',
    },
    {
        'reference-implementation-id': 'RI003A',
        iconPath: '/static/images/RI/RI003A.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA003.JPG',
    },
    {
        'reference-implementation-id': 'RI004A',
        iconPath: '/static/images/RI/RI004A.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA004.JPG',
    },
    {
        'reference-implementation-id': 'RI005',
        iconPath: '/static/images/RI/RI005.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA004.JPG',
    },
    {
        'reference-implementation-id': 'RIIOTEVENTHUB',
        iconPath: '/static/images/RI/RIIOTEVENTHUB.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RIIOTEVENTHUB.JPG',
    },
    {
        'reference-implementation-id': 'RICONSUMERAPP',
        iconPath: '/static/images/RI/RI002.png',
        provisioningPath: '/reference-implementation-provisioning',
        archImg: 'https://pepcmpcmseastus01a.azureedge.net/cms/dev/Architecture%20Diagrams/RA002.JPG',
    },
];
export default RiCatalogsMapping;
